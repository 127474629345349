import { BTAction } from "redux/constants";
import { store } from "redux/store";
import { Domain, DomainActionTypes, DomainMap, DomainPayload, DomainReducer } from "./constants";
import DomainsService from "./DomainsService";

export const updateDomain = (type: DomainActionTypes, name: DomainReducer, value: Domain[] | DomainMap |{id : number , code : string , description : string}[]) => {
  const action: BTAction<DomainPayload> = {
    type,
    payload: {
      domain: {
        name,
        value,
      }
    }
  }
  return action;
}
export const updateHubDeliveryPartners = () => {
  DomainsService.getHubDeliveryPartners().then(
      response => store.dispatch(updateDomain(DomainActionTypes.HUB_DELIVERY_PARTNERS, "hubDeliveryPartners", response))
  );
}
export const updateRoutingParties = () => {
  DomainsService.getRoutingParties().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ROUTING_PARTIES_DOMAIN, "routingParties", response))
  );
}
export const updateCompanies = () => {
  DomainsService.getCompanies().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_COMPANIES_DOMAIN, "companies", response))
  );
}

export const updateCountries = () => {
  DomainsService.getCountries().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_COUNTRIES_DOMAIN, "countries", response))
  );
}

export const updateMetroCodes = () => {
  DomainsService.getMetroCodes().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_CODES_DOMAIN, "metroCodes", response))
  );
}

export const updateMetroCodesIds = () => {
  DomainsService.getMetroCodesIds().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_CODEIDS_DOMAIN, "metroCodesIds", response))
  );
}

export const updateMetroZones = () => {
  DomainsService.getMetroZones().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_METRO_ZONES_DOMAIN, "metroZones", response))
  );
}

export const updateOnFleetTeams = () => {
  DomainsService.getOnFleetTeams().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ONFLEET_TEAMS_DOMAIN, "onFleetTeams", response))
  );
}

export const updateOpsHubs = () => {
  DomainsService.getOpsHubs().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_OPS_HUB_DOMAIN, "opsHubs", response))
  )
}

export const updateHubMetroConfigurations = () => {
  DomainsService.getHubMetroConfigurations().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_HUB_METRO_CONFIGURATIONS, "hubMetroConfigurations", response))
  )

}

export const updateServiceLevels = () => {
  DomainsService.getServiceLevels().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN, "serviceLevels", response))
  )
}

export const updateServiceLevelsToken = () => {
  DomainsService.getServiceLevelsToken().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN_TOKEN, "serviceLevelsToken", response))
  )
}

export const updateStates = () => {
  DomainsService.getStates().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATES_DOMAIN, "states", response))
  );
}

export const updateStateCodes = () => {
  DomainsService.getStateCodes().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATE_CODES_DOMAIN, "stateCodes", response))
  )
}
export const updateStateCodesAbbr = () => {
  DomainsService.getStateCodesAbbr().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_STATES_ABBR_DOMAIN, "stateAbbr", response))
  )
}

export const updateMerchants = () => {
  DomainsService.getMerchants().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_MERCHANTS_DOMAIN, "merchants", response))
  );
}

export const updateCurrencies = () => {
  DomainsService.getCurrencies().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CURRENCIES_DOMAIN, "currencies", response))
  )
}

export const updateZipCodeExceptionTypes = () => {
  DomainsService.getZipCodeExceptionTypes().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CURRENCIES_DOMAIN, "zipCodeExectionTypes", response))
  )
}

export const updateUserRoles = () => {
  DomainsService.getUserRoles().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_USER_ROLES, "userRoles", response))
  )
}

export const updateZipCodes = () => {
  DomainsService.getZipCodes().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_USER_ROLES, "zipCodes", response))
  )
}

export const updateClaimStatus = () => {
  DomainsService.getClaimStatus().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CLAIM_STATUS, "claimStatus", response))
  )
}

export const updateFailureReason = () => {
  DomainsService.getFailureResons().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_FAILURE_REASON, "failureReason", response))
  )
}

export const updateEventTypes = () => {
  DomainsService.getEventTypes().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_EVENT_TYPES, "eventTypes", response))
  );
}

export const updateClaimReasons = () => {
  DomainsService.getClaimReasons().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_CLAIM_REASONS, "claimReasons", response)),
  )
}
export const updateThirdPartyRoutingSearches = () => {
  DomainsService.getThirdPartyRoutingSearches().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_THIRD_PARTY_SHIPMENTS_SEARCHES, "thirdPartyShipmentsSearches", response))
  )
}

export const updateRoutingTaskSearches = () => {
  DomainsService.getRoutingTaskSearches().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ROUTING_TASK_SEARCH, "routingTaskSearches", response))
  )
}

export const updateELSSearches = () => {
  DomainsService.getELSSearches().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_ELS_SEARCH, "elsSearches", response))
  )
}

export const updateMassUnits = () => {
  DomainsService.getMassUnits().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_MASS_UNITS, "massUnits", response))
  )
}

export const updateDistanceUnits = () => {
  DomainsService.getDistanceUnits().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_DISTANCE_UNITS, "distanceUnits", response))
  )
}

export const updateEventTypesWithDesc = () => {
  DomainsService.getEventTypesWithDesc().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_DISTANCE_UNITS, "eventTypesWithDesc", response))
  )
}

export const updatePricingRules = () => {
  DomainsService.getPricingRules().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PRICING_RULE, "pricingRules", response.result))
  );
}

export const updatePodsFailureReasons = () => {
  DomainsService.getPodsFailureReasons().then(
      response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PODS_FAILURE_REASONS, "podsFailureReasons", response))
  )
}

export const updateEventImageTypes = () => {
  DomainsService.getEventImageTypes().then(
      response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_EVENT_IMAGE_TYPES, "eventImageTypes", response))
  )
}

export const updateParcelClaimsSearches = () => {
  DomainsService.getParcelClaimsSearches().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PARCEL_CLAIM_SEARCH, "parcelClaimSearches", response))
  )
}

export const updatePartnerMonitoringSearches = () => {
  DomainsService.getPartnerMonitoringSearches().then(
    response => store.dispatch(updateDomain(DomainActionTypes.UPDATE_PARTNER_MONITORING_SEARCHES, "partnerMonitoringSearches", response))
  )
}

export const initDomains = () => {
  updateHubDeliveryPartners();
  updateCompanies();
  updateRoutingParties();
  updateCountries();
  updateMetroCodes();
  updateMetroCodesIds();
  updateMetroZones();
  updateOnFleetTeams();
  updateServiceLevels();
  updateStates();
  updateMerchants();
  updateCurrencies();
  updateZipCodeExceptionTypes();
  updateUserRoles();
  updateZipCodes();
  updateClaimStatus();
  updateFailureReason();
  updateEventTypes();
  updateClaimReasons();
  updateThirdPartyRoutingSearches();
  updateRoutingTaskSearches();
  updateELSSearches();
  updateMassUnits();
  updateDistanceUnits();
  updateStateCodes();
  updateStateCodesAbbr();
  updateEventTypesWithDesc();
  updateServiceLevelsToken();
  updateMetroCodesIds();
  updateOpsHubs();
  updatePodsFailureReasons();
  updateEventImageTypes();
  updateHubMetroConfigurations();
  updateParcelClaimsSearches();
  updatePartnerMonitoringSearches();
}